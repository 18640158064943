import React, { useLayoutEffect, useMemo, useState } from "react";
import { css } from "goober";

import { DisplayAd } from "@/feature-ads/AdWrapper.jsx";
import adsRefs from "@/feature-ads/refs.mjs";
import CloseIcon from "@/inline-assets/close-icon.svg";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const useShouldShowInFeedBanner = () => {
  const { remoteConfig, showInFeedBanner } = useSnapshot(adsRefs);
  const route = useRoute((a, b) => a?.currentPath === b?.currentPath, {
    isGlobal: true,
  });

  const canShowBanner = useMemo(() => {
    if (!showInFeedBanner) return false;
    const matchedRoute = adsRefs.INFEED_BANNER_PATHS.some((r) =>
      (route?.currentPath ?? "").startsWith(r),
    );
    // return matchedRoute
    return matchedRoute && remoteConfig.adhesion;
  }, [route?.currentPath, showInFeedBanner, remoteConfig.adhesion]);

  const [show, setShow] = useState(false);

  useLayoutEffect(() => {
    if (show !== canShowBanner) {
      setShow(canShowBanner);
    }
  }, [show, canShowBanner]);

  return [show, () => setShow(false)] as const;
};

const InFeedBanner = (): React.ReactNode => {
  const [shouldShowInFeedBanner, onUserClose] = useShouldShowInFeedBanner();

  if (!shouldShowInFeedBanner) return null;

  return (
    <DisplayAd
      placeholder={""}
      id={"display-desktop-anchor-infeed"}
      className={DisplayAdLeaderboard()}
    >
      <button className="close-btn" onClick={onUserClose}>
        <CloseIcon />
      </button>
    </DisplayAd>
  );
};
export default InFeedBanner;

export const feedContainerCls = css`
  @media screen and (min-width: 1240px) {
    &.main {
      min-width: 728px;
    }
  }
`;

const DisplayAdLeaderboard = () => css`
  --width: 728px;
  height: 90px;
  width: var(--width);
  background: transparent;
  outline: none;

  &:has(iframe) {
    background: var(--shade6);
  }

  .close-btn {
    padding: 0;
    --btn-size: var(--sp-4);
    background: var(--shade10-75);
    position: absolute;
    border-radius: 50%;
    top: calc(-1 * var(--btn-size) - var(--sp-1));
    left: calc(var(--width) - var(--btn-size));
    width: var(--btn-size);
    height: var(--btn-size);
    color: var(--shade0-75);
    cursor: pointer;
    border: 1px solid var(--shade0-50);
  }
  &:not(:has(iframe)) .close-btn {
    display: none;
  }
`;
