import adsRefs from "@/feature-ads/refs.mjs";
import InFeedBanner, {
  feedContainerCls,
} from "@/feature-ads-infeed-banner/InFeedBanner.jsx";
import profileRefs from "@/shared/Profile.refs.jsx";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const originals = mapOriginalRefs({
  adsRefs,
  profileRefs,
});

export const setup = () => {
  originals.set({
    adsRefs: {
      showInFeedBanner: true,
    },
    profileRefs: {
      InFeedBanner: InFeedBanner,
      feedContainerCls: feedContainerCls,
    },
  });
};

export const teardown = () => {
  originals.restore();
};
